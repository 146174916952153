import * as QueueActionTypes from '../config/queue_action_types';

// types of action
const Types = {
    GET_VISIT_REQUEST: 'GET_VISIT_REQUEST',
    GET_VISIT_SUCCESS: 'GET_VISIT_SUCCESS',
    GET_VISIT_FAILURE: 'GET_VISIT_FAILURE',
    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_GENERAL_LOCATION: 'GET_GENERAL_LOCATION',
    LIST_LOCATIONS_REQUEST: 'LIST_LOCATIONS_REQUEST',
    LIST_LOCATIONS_SUCCESS: 'LIST_LOCATIONS_SUCCESS',
    LIST_FIRST_AID_RISK_SUBJECTS_REQUEST: 'LIST_FIRST_AID_RISK_SUBJECTS_REQUEST',
    LIST_FIRST_AID_RISK_SUBJECTS_SUCCESS: 'LIST_FIRST_AID_RISK_SUBJECTS_SUCCESS',
    UPDATE_LOCATION_POSITION: 'UPDATE_LOCATION_POSITION',
    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_LOCATION_OBSERVED: 'UPDATE_LOCATION_OBSERVED',
    CLEAR_ERROR: 'CLEAR_ERROR',
    REMOVE_VISIT_LOCALLY: 'REMOVE_VISIT_LOCALLY'
};

// actions
const getVisit = (id) => ({
    type: Types.GET_VISIT_REQUEST,
    meta: {
        offline: {
            effect: { url: Routes.visit_observe_path({ visit_id: id }) },
            commit: { type: Types.GET_VISIT_SUCCESS, meta: { refreshKey: StringHelper.random(8) } },
            rollback: { type: Types.GET_VISIT_FAILURE, meta: { refreshKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.READ, key: id, scope: 'visit' }
        }
    }
});

const getLocation = (id, visitId) => ({
    type: Types.GET_LOCATION_REQUEST,
    meta: {
        id: id,
        offline: (id == 'general' || id == 'first_aid')  ? null : {
            effect: { url: Routes.farm_location_get_path({ farm_location_id: id, visit_id: visitId }) },
            commit: { type: Types.GET_LOCATION_SUCCESS, meta: { visitId, visitId } },
            queue: { method: QueueActionTypes.READ, key: id, scope: 'location_read' }
        }
    }
});

const listLocations = (farmId, visitId) => ({
    type: Types.LIST_LOCATIONS_REQUEST,
    meta: {
        offline: {
            effect: { url: Routes.farm_locations_path({ farm_id: farmId, visit_id: visitId }) },
            commit: { type: Types.LIST_LOCATIONS_SUCCESS, meta: { visitId: visitId, tableKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.READ, key: farmId, scope: 'location_list' }
        }
    }
});

const listFirstAidRiskSubjects = (visitId) => ({
  type: Types.LIST_FIRST_AID_RISK_SUBJECTS_REQUEST,
  meta: {
      offline: {
          effect: { url: Routes.visit_list_farm_form_questions_for_selection_path({visit_id: visitId, report_key: 'first_aid_readiness'}) },
          commit: { type: Types.LIST_FIRST_AID_RISK_SUBJECTS_SUCCESS, meta: { visitId: visitId, tableKey: StringHelper.random(8) } },
          queue: { method: QueueActionTypes.READ, key: visitId, scope: 'first_aid_risk_subject_list' }
      }
  }
});

const updateLocationPosition = (id, position, visitId) => ({
    type: Types.UPDATE_LOCATION_POSITION,
    meta: {
        id: id,
        position: position,
        visitId: visitId
    }
});

const updateVisitStatus = (id, status, token) => ({
    type: Types.UPDATE_STATUS_REQUEST,
    meta: {
        id: id,
        status: status,
        offline: {
            effect: { url: Routes.visit_update_status_path({ visit_id: id, status: status }), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            commit: { type: Types.UPDATE_STATUS_SUCCESS, meta: { id: id, statusKey: StringHelper.random(8) } },
            queue: { method: QueueActionTypes.UPDATE, key: id, scope: 'visit', disableMerge: true }
        }
    }
});

const updateLocationObserved = (token, params) => ({
    type: Types.UPDATE_LOCATION_OBSERVED,
    meta: Object.assign(params, {
        tableKey: StringHelper.random(8),
        offline: {
            effect: { url: Routes.visit_update_location_observed_path(params), method: 'POST', body: JSON.stringify({ authenticity_token: token }) },
            queue: { method: QueueActionTypes.UPDATE, key: `${params.visit_id}-${params.location_id}`, scope: 'visit_location_write' }
        }
    })
});

const clearError = () => ({
    type: Types.CLEAR_ERROR
});

const removeVisitLocally = (id) => ({
  type: Types.REMOVE_VISIT_LOCALLY,
  meta : {
      id: id
  }
});

export default {
    Types,
    getVisit,
    clearError,
    getLocation,
    listLocations,
    listFirstAidRiskSubjects,
    updateLocationPosition,
    updateVisitStatus,
    updateLocationObserved,
    removeVisitLocally
};