import * as QueueActionTypes from '../config/queue_action_types';

// types of action
const Types = {
    GET_REPORT_DATA_REQUEST: 'GET_REPORT_DATA_REQUEST',
    GET_REPORT_DATA_SUCCESS: 'GET_REPORT_DATA_SUCCESS',
    CREATE_VISIT_REPORT_REQUEST: 'CREATE_VISIT_REPORT_REQUEST',
    CREATE_VISIT_REPORT_SUCCESS: 'CREATE_VISIT_REPORT_SUCCESS',
    CREATE_VISIT_REPORT_FAILURE: 'CREATE_VISIT_REPORT_FAILURE',
    UPDATE_VISIT_REPORT_REQUEST: 'UPDATE_VISIT_REPORT_REQUEST',
    UPDATE_VISIT_REPORT_SUCCESS: 'UPDATE_VISIT_REPORT_SUCCESS',
    ADD_WEB_LINK: 'ADD_WEB_LINK',
    REMOVE_WEB_LINK: 'REMOVE_WEB_LINK',
    UPDATE_HEALTH_RISK_ATTACHMENTS: 'UPDATE_HEALTH_RISK_ATTACHMENTS',
    ADD_FILE: 'ADD_FILE',
    REMOVE_FILE: 'REMOVE_FILE',
    TOGGLE_EDIT: 'TOGGLE_EDIT',
    TOGGLE_HEALTH_RISK: 'TOGGLE_HEALTH_RISK',
    TOGGLE_FIRST_AID_RISK: 'TOGGLE_FIRST_AID_RISK',
};

// actions
const getReportData = (visitId, melaAgreement, editing, risks) => ({
    type: Types.GET_REPORT_DATA_REQUEST,
    meta: {
        visitId: visitId,
        editing: editing,
        risks: risks,
        offline: {
            effect: { url: Routes.visit_prepare_report_path({ visit_id: visitId }) },
            commit: { type: Types.GET_REPORT_DATA_SUCCESS, meta: { editing: editing, visitId: visitId, melaAgreement: melaAgreement }},
            queue: { method: QueueActionTypes.READ, key: visitId, scope: 'visit_report' }
        }
    }
});

const createVisitReport = (token, params, visitId, melaAgreement) => ({
    type: Types.CREATE_VISIT_REPORT_REQUEST,
    meta: {
        params: { ...params },
        visitId: visitId,
        melaAgreement: melaAgreement,
        offline: {
            effect: { url: Routes.visit_report_create_path({ visit_id: visitId }), method: 'POST', body: JSON.stringify({ authenticity_token: token, visit_report: formatParams(checkMelaAgreement(params, melaAgreement)) }) },
            commit: { type: Types.CREATE_VISIT_REPORT_SUCCESS, meta: { visitId: visitId, melaAgreement: melaAgreement } },
            // This is treated as a UPDATE action to enable using visit ID as a key.
            queue: { method: QueueActionTypes.UPDATE, key: visitId, scope: 'visit_report' }
        }
    }
});

const updateVisitReport = (id, token, params, visitId, melaAgreement) => ({
    type: Types.UPDATE_VISIT_REPORT_REQUEST,
    meta: {
        id: id,
        params: { ...params },
        visitId: visitId,
        melaAgreement: melaAgreement,
        offline: {
            effect: { url: Routes.visit_report_update_path({ visit_report_id: id }), method: 'POST', body: JSON.stringify({ authenticity_token: token, visit_report: formatParams(checkMelaAgreement(params, melaAgreement)) }) },
            commit: { type: Types.UPDATE_VISIT_REPORT_SUCCESS, meta: { id: id, visitId: visitId, melaAgreement: melaAgreement } },
            queue: { method: QueueActionTypes.UPDATE, key: visitId, scope: 'visit_report' }
        }
    }
});

const addWebLink = (link, visitId) => ({
    type: Types.ADD_WEB_LINK,
    meta: {
        link: link,
        visitId: visitId
    }
});

const removeWebLink = (link, visitId) => ({
    type: Types.REMOVE_WEB_LINK,
    meta: {
        link: link,
        visitId: visitId
    }
});

const updateHealthRiskAttachments = (visitId, healthRiskId, attachmentsAndOrientations) => ({
  type: Types.UPDATE_HEALTH_RISK_ATTACHMENTS,
  meta: {
      visitId: visitId,
      healthRiskId: healthRiskId,
      attachmentsAndOrientations: attachmentsAndOrientations,
  }
});

const addFile = (file, visitId) => ({
    type: Types.ADD_FILE,
    meta: {
        file: file,
        visitId: visitId
    }
});

const removeFile = (idx, visitId) => ({
    type: Types.REMOVE_FILE,
    meta: {
        idx: idx,
        visitId: visitId
    }
});

const toggleEdit = (disabled, visitId, risks) => ({
    type: Types.TOGGLE_EDIT,
    meta: {
        disabled: disabled,
        visitId: visitId,
        risks: risks
    }
});

const toggleHealthRisk = (id, selected, visitId) => ({
    type: Types.TOGGLE_HEALTH_RISK,
    meta: {
        id: id,
        selected: selected,
        visitId: visitId
    }
});

const toggleFirstAidRisk = (id, selected, visitId) => ({
    type: Types.TOGGLE_FIRST_AID_RISK,
    meta: {
        id: id,
        selected: selected,
        visitId: visitId,
    }
});

// Removes health risk information from params if customer has not agreed to saving them to the server.
function checkMelaAgreement(params, melaAgreement) {
    if (!melaAgreement) {
        delete params.health_risk_ids;
        delete params.fa_recommendations;
        delete params.first_aid_risk_ids;
    }
    return params;
}

// Modify params to similar format than when submitting the form normally
function formatParams(params) {
    params.web_links = (params.web_links || []).map(l => JSON.stringify(l));
    params.files = { ...params.fileParams };
    params.health_risk_ids = (params.health_risk_ids || []).filter(id => !isNaN(id));
    let far = params.fa_recommendations || {};
    params.fa_recommendations = Object.keys(far).map((i) => ({id: i, action_description: (far)[i]}));
    delete params.fileParams;

    return params;
}

export default {
    Types,
    getReportData,
    createVisitReport,
    updateVisitReport,
    addWebLink,
    removeWebLink,
    updateHealthRiskAttachments,
    addFile,
    removeFile,
    toggleEdit,
    toggleHealthRisk,
    toggleFirstAidRisk,
};