import ACTIONS from "../actions/visit_report";
import HEALTH_RISK_ACTIONS from "../actions/health_risk";
import _ from "lodash";

const defaultState = {
    reports: {},
    webLinks: [],
    phrases: {},
    firstAidRisks: [],
    // Reports that are being edited
    openReports: {}
};

const visitReportReducer = (state = defaultState, action) => {
    switch (action.type) {
        // GET VISIT REPORT DATA
        case ACTIONS.Types.GET_REPORT_DATA_REQUEST: {
            let newState = _.cloneDeep(state);
            const { visitId, editing, risks } = action.meta;

            if (editing) {
                // Open report for editing, If trying to load data in edit mode.
                newState.openReports[visitId] = _.cloneDeep(newState.reports[visitId]);
                if (!newState.openReports[visitId].health_risk_ids) {
                    newState.openReports[visitId].health_risk_ids = risks;
                }
            } else {
                // Closes edited report.
                // This prompts form component to navigate away from the edit mode.
                delete newState.openReports[action.meta.visitId];
            }

            return newState;
        }

        case ACTIONS.Types.GET_REPORT_DATA_SUCCESS: {
            let newState = _.cloneDeep(state);
            let newReport = action.payload.visit_report || {};
            const visitId = newReport.visit_id || action.meta.visitId;

            let oldReport = newState.reports[visitId];
            if (oldReport) {
                if (!action.meta.melaAgreement) {
                    // Only update health risk ids if customer has agreed to that.
                    newReport.health_risk_ids = oldReport.health_risk_ids;
                    newReport.fa_recommendations = oldReport.fa_recommendations;
                    newReport.first_aid_risk_ids = oldReport.first_aid_risk_ids;
                } else {
                    // Keep locally saved health risks from old report.
                    (oldReport.health_risk_ids || []).filter(id => isNaN(id)).forEach(id => newReport.health_risk_ids.push(id));
                }
            }

            newReport.chemicals = action.payload.chemicals;
            newReport.numberOfChemicals = action.payload.chemicals.length;

            newState.reports[visitId] = newReport;

            newState.webLinks = action.payload.web_links;
            newState.phrases = action.payload.phrases;
            newState.firstAidRisks = action.payload.first_aid_risks;

            // Open report for editing, if there was no data before and in editing mode.
            if (!newState.openReports[visitId] && action.meta.editing) {
                newState.openReports[visitId] = _.cloneDeep(newState.reports[visitId]);
            }

            return newState;
        }

        // CREATE VISIT REPORT
        case ACTIONS.Types.CREATE_VISIT_REPORT_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.reports[action.meta.visitId] = action.meta.params;

            // Closes edited report.
            // This prompts form component to navigate away from the edit mode.
            delete newState.openReports[action.meta.visitId];

            return newState;
        }
        case ACTIONS.Types.CREATE_VISIT_REPORT_SUCCESS: {
            let newState = _.cloneDeep(state);
            let newReport = action.payload || {};

            let oldReport = newState.reports[action.payload.visit_id];
            if (oldReport) {
                if (!action.meta.melaAgreement) {
                    // Only update health risk ids if customer has agreed to that.
                    newReport.health_risk_ids = oldReport.health_risk_ids;
                    newReport.fa_recommendations = oldReport.fa_recommendations;
                    newReport.first_aid_risk_ids = oldReport.first_aid_risk_ids;
                } else {
                    // Keep locally saved health risks from old report.
                    (oldReport.health_risk_ids || []).filter(id => isNaN(id)).forEach(id => newReport.health_risk_ids.push(id));
                }
            }

            newState.reports[action.payload.visit_id] = newReport;

            return newState;
        }

        // UPDATE VISIT REPORT
        case ACTIONS.Types.UPDATE_VISIT_REPORT_REQUEST: {
            let newState = _.cloneDeep(state);

            let report = newState.reports[action.meta.visitId];
            report = Object.assign(report, action.meta.params);
            
            // Closes edited report.
            // This prompts form component to navigate away from the edit mode.
            delete newState.openReports[action.meta.visitId];

            return newState;
        }
        case ACTIONS.Types.UPDATE_VISIT_REPORT_SUCCESS: {
            let newState = _.cloneDeep(state);
            let newReport = action.payload || {};
            
            let oldReport = newState.reports[action.payload.visit_id];
            if (oldReport) {
                if (!action.meta.melaAgreement) {
                    // Only update health risk ids if customer has agreed to that.
                    newReport.health_risk_ids = oldReport.health_risk_ids;
                    newReport.fa_recommendations = oldReport.fa_recommendations;
                    newReport.first_aid_risk_ids = oldReport.first_aid_risk_ids;
                } else {
                    // Keep locally saved health risks from old report.
                    (oldReport.health_risk_ids || []).filter(id => isNaN(id)).forEach(id => newReport.health_risk_ids.push(id));
                }
            }

            newState.reports[action.payload.visit_id] = newReport;

            return newState;
        }

        // ADD WEB LINK
        case ACTIONS.Types.ADD_WEB_LINK: {
            let newState = _.cloneDeep(state);
            newState.openReports[action.meta.visitId].web_links = newState.openReports[action.meta.visitId].web_links || [];
            newState.openReports[action.meta.visitId].web_links.push(action.meta.link);

            return newState;
        }

        // REMOVE WEB LINK
        case ACTIONS.Types.REMOVE_WEB_LINK: {
            let newState = _.cloneDeep(state);
            const { link, visitId } = action.meta;
            newState.openReports[visitId].web_links = newState.openReports[visitId].web_links.filter(l => l.id !== link.id);

            return newState;
        }

        // UPDATE HEALTH RISK ATTACHMENTS
        // Updates state.reports directly
        case ACTIONS.Types.UPDATE_HEALTH_RISK_ATTACHMENTS: {
            let newState = _.cloneDeep(state);
            const { visitId, healthRiskId, attachmentsAndOrientations } = action.meta;
            let selectedHealthRiskAttachments = newState.openReports[visitId].selected_health_risk_attachments;

            if (selectedHealthRiskAttachments == null) {
                selectedHealthRiskAttachments = {};
            }
            selectedHealthRiskAttachments[healthRiskId] = attachmentsAndOrientations;

            // Clean up those with no attachments selected
            for (const hrId in selectedHealthRiskAttachments) {
                if (Array.isArray(selectedHealthRiskAttachments[hrId]) && selectedHealthRiskAttachments[hrId].length == 0) {
                    delete selectedHealthRiskAttachments[hrId];
                }
            }
            
            newState.reports[visitId].selected_health_risk_attachments = selectedHealthRiskAttachments;

            return newState;
        }

        // ADD FILE
        case ACTIONS.Types.ADD_FILE: {
            let newState = _.cloneDeep(state);
            newState.openReports[action.meta.visitId].files = newState.openReports[action.meta.visitId].files || [];
            newState.openReports[action.meta.visitId].files.push(action.meta.file);

            return newState;
        }

        // REMOVE FILE
        case ACTIONS.Types.REMOVE_FILE: {
            let newState = _.cloneDeep(state);
            const { idx, visitId } = action.meta;
            newState.openReports[visitId].files.splice(idx, 1);

            return newState;
        }

        // TOGGLE FORM EDIT MODE
        case ACTIONS.Types.TOGGLE_EDIT: {
            let newState = _.cloneDeep(state);
            const { disabled, visitId, risks } = action.meta;

            if (disabled) {
                // revert changes
                delete newState.openReports[visitId];
            } else {
                // Open report for editing
                newState.openReports[visitId] = _.cloneDeep(newState.reports[visitId]);
                if (!newState.openReports[visitId].health_risk_ids) {
                    newState.openReports[visitId].health_risk_ids = risks;
                }
                if (!newState.openReports[visitId].first_aid_risk_ids) {
                    newState.openReports[visitId].first_aid_risk_ids = [];
                }
            }

            return newState;
        }

        // TOGGLE HEALTH RISK SELECTION
        case ACTIONS.Types.TOGGLE_HEALTH_RISK: {
            let newState = _.cloneDeep(state);
            const { id, selected, visitId } = action.meta;

            if (selected) {
                newState.openReports[visitId].health_risk_ids.push(id);
            } else {
                newState.openReports[visitId].health_risk_ids = newState.openReports[visitId].health_risk_ids.filter(r => r != id);
            }

            return newState;
        }

        case HEALTH_RISK_ACTIONS.Types.CREATE_HEALTH_RISK_SUCCESS: {
            let newState = _.cloneDeep(state);

            const { strId } = action.meta;
            const { id, visit_id } = action.payload;
            const idx = ((newState.openReports[visit_id] || {}).health_risk_ids || []).indexOf(strId);

            if (idx !== -1) {
                newState.openReports[visit_id].health_risk_ids[idx] = id;
            }

            return newState;
        }

        case ACTIONS.Types.TOGGLE_FIRST_AID_RISK: {
            let newState = _.cloneDeep(state);
            const { id, selected, visitId } = action.meta;

            let report = newState.openReports[visitId];
            if (selected) {
                report.first_aid_risk_ids.push(id);
            } else if (selected !== undefined) {
                report.first_aid_risk_ids = report.first_aid_risk_ids.filter(r => r != id);
            }

            return newState;
        }

        default:
            return state;
    }
};
export default visitReportReducer;