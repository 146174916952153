import ACTIONS from "../actions/visit";
import _ from "lodash";

const defaultState = {
    visits: {},
    locations: {},
    loadFactors: {},
    loadFactorOptions: [],
    firstAidRiskSubjects: {},
    actionClasses: {},
    actionStatuses: {},
    risk: {},
    images: {},
    error: {},
    refreshKey: null
};

const visitReducer = (state = defaultState, action) => {
    switch (action.type) {
        // GET VISIT
        case ACTIONS.Types.GET_VISIT_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.visits[action.payload.visit.id] = action.payload.visit;
            newState.visits[action.payload.visit.id].farm = action.payload.farm;
            newState.visits[action.payload.visit.id].visitorOptions = action.payload.visitor_options;

            newState.loadFactors = action.payload.load_factors;
            newState.loadFactorOptions = action.payload.load_factor_options;
            newState.actionClasses = action.payload.action_classes;
            newState.actionStatuses = action.payload.action_statuses;
            newState.risk = action.payload.risk;

            newState.refreshKey = action.meta.refreshKey;

            return newState;
        }

        case ACTIONS.Types.GET_VISIT_FAILURE: {
            let newState = _.cloneDeep(state);

            newState.error = action.payload.response;

            newState.refreshKey = action.meta.refreshKey;

            return newState;
        }

        // GET LOCATION
        case ACTIONS.Types.GET_LOCATION_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.locations[action.meta.visitId][action.payload.id] = action.payload;

            return newState;
        }

        // LIST LOCATIONS
        case ACTIONS.Types.LIST_LOCATIONS_SUCCESS: {
            let newState = _.cloneDeep(state);
            
            let locations = {};
            action.payload.locations.forEach(function (l) { locations[l.id] = l });
            newState.locations[action.meta.visitId] = locations;
            newState.images[action.meta.visitId] = action.payload.image_id

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        // LIST FIRST AID RISK SUBJECTS
        case ACTIONS.Types.LIST_FIRST_AID_RISK_SUBJECTS_SUCCESS: {
          let newState = _.cloneDeep(state);

          newState.firstAidRiskSubjects[action.meta.visitId] = action.payload;
          
          newState.tableKey = action.meta.tableKey;

          return newState;
      }

        // UPDATE LOCATION POSITION
        case ACTIONS.Types.UPDATE_LOCATION_POSITION: {
            let newState = _.cloneDeep(state);
             newState.locations[action.meta.visitId][action.meta.id].top = action.meta.position.top;
             newState.locations[action.meta.visitId][action.meta.id].left = action.meta.position.left;

            return newState;
        }

        // UPDATE VISIT STATUS
        case ACTIONS.Types.UPDATE_STATUS_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.visits[action.meta.id].status = action.meta.status;

            return newState;
        }

        case ACTIONS.Types.UPDATE_STATUS_SUCCESS: {
            let newState = _.cloneDeep(state);

            // Forces visit to update to retrieve new status
            newState.visits[action.meta.id].statusKey = action.meta.statusKey;

            return newState;
        }

        // UPDATE LOCATION OBSERVED
        case ACTIONS.Types.UPDATE_LOCATION_OBSERVED: {
            let newState = _.cloneDeep(state);
            newState.locations[action.meta.visit_id][action.meta.location_id].observed = action.meta.observed;

            newState.tableKey = action.meta.tableKey;

            return newState;
        }

        case ACTIONS.Types.CLEAR_ERROR: {
            let newState = _.cloneDeep(state);

            newState.error = {};

            return newState;
        }

        case ACTIONS.Types.REMOVE_VISIT_LOCALLY: {
          const newState = _.cloneDeep(state);

          delete newState.visits[action.meta.id];

          newState.tableKey = action.meta.tableKey;

          return newState;
      }

        default:
            return state;
    }
};
export default visitReducer;